<template>
  <div>
    <a-modal
      :visible="visible"
      title="编辑"
      :width="960"
      :confirmLoading="confirmLoading"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleConfirm"
    >
      <a-form :form="dataForm" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
        <a-row>
          <a-col :span="12">
            <a-form-item label="客户名称">
              <a-input
                v-decorator="[
                  'client_name',
                  {
                    initialValue: item.client_name,
                    rules: [
                      { required: true, message: '请输入客户名称' },
                      { max: 256, message: '超出最大长度(256)' },
                    ],
                  },
                ]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="跟进人">
              <UserSelect
                v-decorator="[
                  'follow_up_person',
                  { initialValue: item.follow_up_person, rules: [{ required: true, message: '请选择跟进人' }] },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="联系人">
              <a-input
                v-decorator="[
                  'contact',
                  {
                    initialValue: item.contact,
                    rules: [
                      { required: true, message: '请输入联系人' },
                      { max: 256, message: '超出最大长度(256)' },
                    ],
                  },
                ]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="联系电话">
              <a-input
                v-decorator="[
                  'phone',
                  {
                    initialValue: item.phone,
                    rules: [
                      { required: true, message: '请输入联系电话' },
                      { max: 32, message: '超出最大长度(32)' },
                    ],
                  },
                ]"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="目标系统">
              <a-select
                v-decorator="[
                  'target_system',
                  { initialValue: item.target_system, rules: [{ required: true, message: '请选择目标系统' }] },
                ]"
                :allowClear="true"
                style="width: 100%"
              >
                <a-select-option key="WMS" value="WMS">WMS</a-select-option>
                <a-select-option key="WCS" value="WCS">WCS</a-select-option>
                <a-select-option key="ERP" value="ERP">ERP</a-select-option>
                <a-select-option key="MES" value="MES">MES</a-select-option>
                <a-select-option key="TMS" value="TMS">TMS</a-select-option>
                <a-select-option key="Others" value="Others">Others</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="客户意向">
              <a-select
                v-decorator="[
                  'intention',
                  { rules: [{ required: true, message: '请选择客户意向' }], initialValue: item.intention },
                ]"
                :allowClear="true"
                style="width: 100%"
              >
                <a-select-option key="low" value="low">低</a-select-option>
                <a-select-option key="medium" value="medium">中</a-select-option>
                <a-select-option key="high" value="high">高</a-select-option>
                <a-select-option key="none" value="none">无</a-select-option>
                <a-select-option key="pending" value="pending">待定</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="客户来源">
              <a-select v-decorator="['source', { initialValue: item.source }]" :allowClear="true" style="width: 100%">
                <a-select-option key="系统注册" value="系统注册">系统注册</a-select-option>
                <a-select-option key="新媒体或互联网" value="新媒体或互联网">新媒体或互联网</a-select-option>
                <a-select-option key="老客户介绍" value="老客户介绍">老客户介绍</a-select-option>
                <a-select-option key="外出拜访" value="外出拜访">外出拜访</a-select-option>
                <a-select-option key="电话销售" value="电话销售">电话销售</a-select-option>
                <a-select-option key="其他" value="其他">其他</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="是否已加微信">
              <a-switch v-decorator="['is_add_wechat', { initialValue: item.is_add_wechat, valuePropName: 'checked' }]" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="是否签约">
              <a-switch
                v-decorator="['signed', { initialValue: item.signed, valuePropName: 'checked' }]"
                @change="onChangeSigned"
              />
            </a-form-item>
          </a-col>
          <a-col v-if="signed" :span="12">
            <a-form-item label="合同总金额(元)">
              <a-input-number v-decorator="['contract_amount', { initialValue: item.contract_amount }]" style="width: 100%" />
            </a-form-item>
          </a-col>
          <a-col v-if="signed" :span="12">
            <a-form-item label="已回款金额(元)">
              <a-input-number
                v-decorator="['received_amount', { initialValue: item.received_amount }]"
                style="width: 100%"
                @change="(value) => (received_amount = value)"
              />
            </a-form-item>
          </a-col>
          <a-col v-if="signed" :span="12">
            <a-form-item label="应付金额(元)">
              <a-input-number v-decorator="['payable_amount', { initialValue: item.payable_amount }]" style="width: 100%" />
            </a-form-item>
          </a-col>
          <a-col v-if="signed" :span="12">
            <a-form-item label="已付金额(元)">
              <a-input-number
                v-decorator="['paid_amount', { initialValue: item.paid_amount }]"
                style="width: 100%"
                @change="(value) => (paid_amount = value)"
              />
            </a-form-item>
          </a-col>
          <a-col v-if="signed" :span="12">
            <a-form-item label="已开票金额(元)">
              <a-input-number v-decorator="['invoice_amount', { initialValue: item.invoice_amount }]" style="width: 100%" />
            </a-form-item>
          </a-col>
          <a-col v-if="signed" :span="12">
            <a-form-item label="实收利润(元)">
              <a-input-number disabled :value="actualProfit" style="width: 100%" />
            </a-form-item>
          </a-col>
          <a-col v-if="signed" :span="12">
            <a-form-item label="下笔回款日期">
              <a-date-picker
                v-decorator="['next_receipt_date', { initialValue: item.next_receipt_date }]"
                valueFormat="YYYY-MM-DD"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="下次回访日期">
              <a-date-picker
                v-decorator="['next_visit_date', { initialValue: item.next_visit_date }]"
                valueFormat="YYYY-MM-DD"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="城市">
              <a-cascader
                v-decorator="['city_code', { initialValue: item.city_code }]"
                placeholder=""
                :options="provinceAndCityData"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="拜访频率">
              <a-select
                v-decorator="['visit_frequency', { initialValue: item.visit_frequency }]"
                :allowClear="true"
                style="width: 100%"
              >
                <a-select-option key="weekly" value="weekly">每周</a-select-option>
                <a-select-option key="biweekly" value="biweekly">每两周</a-select-option>
                <a-select-option key="monthly" value="monthly">每月</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="客户需求">
              <a-textarea
                v-decorator="['demand', { initialValue: item.demand, rules: [{ max: 64, message: '超出最大长度(64)' }] }]"
                :auto-size="{ minRows: 4 }"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="报价记录">
              <a-textarea
                v-decorator="[
                  'quotation_record',
                  { initialValue: item.quotation_record, rules: [{ max: 256, message: '超出最大长度(64)' }] },
                ]"
                :auto-size="{ minRows: 4 }"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="备注">
              <a-textarea
                v-decorator="['remark', { initialValue: item.remark }]"
                :auto-size="{ minRows: 4 }"
                :allowClear="true"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="附件">
              <LeadAttachmentUpload
                v-decorator="['lead_attachment_set', { initialValue: item.lead_attachment_set }]"
                :initialItems="item.lead_attachment_items"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { provinceAndCityData, codeToText } from "element-china-area-data";
import { leadUpdate } from "@/apis/data";

export default {
  components: {
    UserSelect: () => import("@/components/UserSelect"),
    LeadAttachmentUpload: () => import("@/components/LeadAttachmentUpload"),
  },
  props: ["visible", "item"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      provinceAndCityData,
      confirmLoading: false,
      dataForm: null,
      signed: false,

      received_amount: 0,
      paid_amount: 0,
    };
  },
  computed: {
    actualProfit() {
      return this.received_amount - this.paid_amount;
    },
  },
  methods: {
    handleConfirm() {
      this.dataForm.validateFields((error, values) => {
        if (error === null) {
          this.confirmLoading = true;

          const cityName = values.city_code.map((code) => codeToText[code]).join("/");
          leadUpdate({ id: this.item.id, ...values, city_name: cityName })
            .then((data) => {
              this.$emit("update", data);
              this.$message.success("编辑成功");
              this.handleCancel();
            })
            .catch((error) => {
              const errorFields = Object.entries(error).reduce((acc, [field, errors]) => {
                acc[field] = { value: values[field], errors: errors.map((value) => new Error(value)) };
                return acc;
              }, {});
              this.dataForm.setFields(errorFields);
            })
            .finally(() => {
              this.confirmLoading = false;
            });
        }
      });
    },
    handleCancel() {
      this.$emit("cancel", false);
    },
    onChangeSigned(value) {
      this.signed = value;
    },
  },
  mounted() {
    this.dataForm = this.$form.createForm(this);
    this.signed = this.item.signed;
    this.received_amount = this.item.received_amount;
    this.paid_amount = this.item.paid_amount;
  },
};
</script>

<style scoped></style>
